<template>
  <div id="node">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <i class="fas fa-code-branch"></i>
          {{ node.name }} <small>({{ node.mac }})</small>
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="redirect('/member/mornitor')">Plants</a></li>
            <li><a @click="redirect('/member/branch/' + node.b_id)">{{ branch.name }}</a></li>
            <li class="is-active"><a>{{ node.name }} <small class="ml-1 is-size-7">({{ node.mac }})</small></a></li>
          </ul>
        </nav>

        <div class="row">
          <div class="col-6">
            <input type="month" class="input is-small has-text-centered" v-model="inputMonth" style="width:120px">
          </div>
          <div class="col-6 has-text-right">
            <div class="dropdown is-right is-hoverable">
              <div class="dropdown-menu" id="select-parameter" role="menu">
                <div class="dropdown-content" style="width:250px;">
                  <div class="dropdown-item has-text-centered p-2">
                    <div class="mb-1">
                      <article class="message">
                        <div class="message-header py-2">
                          <small class="w-100 has-text-centered">Select Parameter</small>
                        </div>
                        <div class="message-body has-text-left is-size-7 p-2">
                          <div v-for="(val, key) in params_opt" :key="key">
                            <label class="checkbox mb-1">
                              <input type="checkbox" v-model="params" :value="val">
                              {{ val.label }}
                            </label>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div class="buttons are-small is-centered">
                      <button type="button" class="button px-3 is-info" @click="restore"> Restore </button>
                      <button type="button" class="button px-3 is-default" @click="clear"> Clear </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dropdown-trigger">
                <button class="button is-small" aria-haspopup="true" aria-controls="select-parameter">
                  <span>Select Parameter</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <hr>
          <apexcharts height="375" type="bar" :options="chartOptions" :series="series"></apexcharts>
        </div>

        <!-- <pre>{{ logger }}</pre> -->
        <pre>{{ last_log }}</pre>

      </div>
    </div>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MemberNodeLogger',
  components: { apexcharts: VueApexCharts, },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
    connected: Boolean,
    // topic: String,
    topic: Array,
  },
  data() {
    return {
      n_id: this.$route.params.id,
      branch: {},
      node: {},

      logger: [],
      last_log: {},

      inputMonth: this.formatMonth(new Date()),
      data_status: "",
      params: [],
      params_opt: [
        { label: "(kWh)Daily Generation", val: "abc" },
        { label: "(THB)DailyIncome", val: "abc" },
        { label: "(kWh /㎡)Day irradiation amount", val: "abc" },
        { label: "(%)Day pr", val: "abc" },
      ]
    }
  },
  created() {
    const INDEX = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.n_id));
    if (INDEX !== -1) {
      this.node = this.nodes[INDEX];

      const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
      this.branch = this.branchs[INDEX_B];
    } else {
      this.redirect("/member/mornitor")
    }
  },
  mounted() {
    console.log(this.node);
    this.get_logger_log(this.inputMonth);
    this.restore();
  },
  methods: {
    async get_logger_log() {
      var url = this.api + 'user/get_logger_log/' + this.secret;
      var data = new FormData();
      data.set("n_id", this.n_id);
      data.set("year", this.year);
      data.set("month", this.month);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.logger = response.data.data;
            this.last_log = response.data.data1;
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
    },
    formatMonth(date = null) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;

      return [year, month].join('-');
    },
    restore() {
      this.clear();
      this.params.push(this.params_opt[0]);
    },
    clear() {
      this.params = [];
    },
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
  },
  computed: {
    year() {
      return this.inputMonth.substring(0, 4);
    },
    month() {
      return this.inputMonth.slice(-2);
    },
    daysInMonth() {
      return this.getDaysInMonth(this.year, this.month);
    },
    arrayDaysInMonth() {
      let array = [];
      for (let i = 1; i <= this.daysInMonth; i++) {
        array.push(i);
      }
      return array;
    },
    arrayDataInMonth() {
      let array = [];
      for (let i = 1; i <= this.daysInMonth; i++) {
        array.push(Math.floor(Math.random() * 51));
      }
      return array;
    },
    chartOptions() {
      return {
        xaxis: {
          categories: this.arrayDaysInMonth,
        },
        dataLabels: {
          enabled: false
        }
      }
    },
    series() {
      let array = [];
      this.params.forEach(param => {
        array.push(
          {
            name: param.label,
            data: this.arrayDataInMonth,
          },
        )
      });
      return array;
    }
  },
  watch: {
    inputMonth() {
      setTimeout(() => {
        this.get_logger_log();
      }, 100);
    }
  }
}
</script>
